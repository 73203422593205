import React, { useState, useEffect } from 'react';
import { motion, useViewportScroll } from 'framer-motion';

const AppExample = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const { scrollYProgress } = useViewportScroll();

  useEffect(() => {
    // Update scroll progress value
    const unsubscribe = scrollYProgress.onChange(setScrollProgress);
    return () => unsubscribe();
  }, [scrollYProgress]);

  const containerVariants = {
    enter: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        staggerChildren: 0.5,
      },
    },
  };

  const sectionVariants = {
    enter: {
      opacity: 1,
      y: 0,
    },
    exit: {
      opacity: 0,
      y: '-100vh',
    },
  };

  return (
    <motion.div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
      variants={containerVariants}
      initial='exit'
      animate='enter'
      exit='exit'
    >
      <motion.section
        style={{
          height: '100vh',
          backgroundColor: 'blue',
        }}
        variants={sectionVariants}
      >
        <h1>Section 1</h1>
      </motion.section>
      <motion.section
        style={{
          height: '100vh',
          backgroundColor: 'red',
        }}
        variants={sectionVariants}
      >
        <h1>Section 2</h1>
      </motion.section>
    </motion.div>
  );
};

export default AppExample;
