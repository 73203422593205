// Photos from https://citizenofnowhe.re/lines-of-the-cityspring
import './styles.css';
import { useRef, useState, useEffect } from 'react';
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  MotionValue,
  useAnimation,
} from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import FeatherIcon from 'feather-icons-react';
import fileDownload from 'js-file-download';
import BASE_URL from './BASE_URL';
import axios from 'axios';
import { useLocation, Route, Routes } from 'react-router-dom';

//Components
import CircleDown from './components/circleDown';
import CircleUp from './components/circleUp';

function useParallax(value, distance) {
  return useTransform(value, [0, 1000], [-distance, distance]);
}

// Section
const sectionVariant = {
  visible: { opacity: 1, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 0 },
};

// Letters
const lettersVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1, delay: 1 } },
  hidden: { opacity: 0, y: 100 },
};

function FirstPage({ identificador, userInfo }) {
  const ref = useRef(null);

  // ANIMATIONS
  const control = useAnimation();
  const [refer, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start('visible');
    }
    // else {
    //   control.start('hidden');
    // }
  }, [control, inView]);

  return (
    <motion.section
      // initial={{ opacity: 0, transition: { duration: 1 } }}
      variants={sectionVariant}
      initial='hidden'
      animate='visible'
      // transition={{ delay: 1 }}
    >
      <div ref={ref}>
        <div
          style={{
            position: 'absolute',
            top: 40,
            left: 30,
          }}
        >
          <img
            // src='./logo192.png'
            src={userInfo && userInfo.icon1}
            style={{ width: 'auto', height: '30px' }}
            alt='logo'
          />
        </div>
        <motion.div
          style={{
            position: 'absolute',
            bottom: 80,
            left: 30,
            right: 30,
          }}
          ref={refer}
          initial='hidden'
          // animate='visible'
          animate={control}
          variants={lettersVariant}
        >
          <p style={{ fontSize: '40px', fontWeight: 'bold', color: '#004989' }}>
            {userInfo && userInfo.nome}
            {/* Rodrigo Mercador */}
          </p>
          <p>
            {userInfo && userInfo.cargo}
            {/* Frontend Developer */}
          </p>
          <CircleDown userInfo={userInfo} />
        </motion.div>
      </div>
      {/* <motion.h2 style={{ y }}>{`#00${id}`}</motion.h2> */}
    </motion.section>
  );
}

function SecondPage({ identificador, userInfo }) {
  const ref = useRef(null);

  const vCard = () => {
    axios
      .get(`${BASE_URL}/cartao/${identificador}/vcf`)
      .then(({ data }) => {
        fileDownload(data, `${userInfo.nome}.vcf`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ANIMATIONS
  const control = useAnimation();
  const [refer, inView] = useInView();
  // const lettersVariant = {
  //   visible: { opacity: 1, y: 0, transition: { duration: 1, delay: 0.25 } },
  //   hidden: { opacity: 0, y: 100 },
  // };

  useEffect(() => {
    if (inView) {
      control.start('visible');
    }
    // else {
    //   control.start('hidden');
    // }
  }, [control, inView]);

  return (
    <motion.section
      style={{ color: 'white' }}
      variants={sectionVariant}
      initial='hidden'
      animate='visible'
    >
      <div ref={ref}>
        <div
          style={{
            position: 'absolute',
            top: 40,
            left: 30,
          }}
        >
          <img
            // src='./logo192.png'
            src={userInfo && userInfo.icon2}
            style={{ width: 'auto', height: '30px' }}
            alt='logo'
          />
        </div>
        <motion.div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 30,
            right: 30,
          }}
          variants={lettersVariant}
          ref={refer}
          initial='hidden'
          // animate='visible'
          animate={control}
        >
          <div style={{ paddingBottom: '30px' }}>
            <p style={{ fontWeight: 'bold', marginBottom: 10 }}>
              {' '}
              {userInfo && userInfo.empresa}
              {/* Grupo JAF */}
            </p>
            <p style={{ fontWeight: 'bold', fontSize: '22px' }}>
              {' '}
              {userInfo && userInfo.email}
              {/* rodrigo.mercador@ynb.pt */}
            </p>
            <p style={{ fontWeight: 'bold', fontSize: '22px' }}>
              {' '}
              {userInfo && userInfo.contato}
              {/* +351 45698713 */}
            </p>
          </div>
          <hr />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '20px 0px',
            }}
          >
            <FeatherIcon
              icon='map-pin'
              size={20}
              style={{ strokeWidth: '1.5', marginRight: '10px' }}
            />
            <p style={{ fontSize: '16px', margin: 10 }}>
              {userInfo && userInfo.morada}
            </p>
          </div>
          {userInfo && userInfo.linkedin ? (
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '20px 0px',
                }}
              >
                <FeatherIcon
                  icon='linkedin'
                  size={20}
                  style={{ strokeWidth: '1.5', marginRight: '10px' }}
                />

                <p
                  style={{
                    fontSize: '16px',
                    margin: 10,
                    textDecoration: 'none',
                  }}
                >
                  <a
                    style={{
                      textDecoration: 'none',
                      color: 'white',
                      cursor: 'pointer',
                    }}
                    href={`${userInfo && userInfo.linkedin}`}
                  >
                    LinkedIn
                  </a>
                </p>
              </div>
            </>
          ) : null}

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '20px 0px',
            }}
            onClick={() => vCard()}
          >
            <FeatherIcon
              icon='credit-card'
              size={20}
              style={{ strokeWidth: '1.5', marginRight: '10px' }}
            />
            <p style={{ fontSize: '16px', margin: 10 }}>Transferir vCard</p>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingBottom: '20px',
            }}
          >
            <div>
              <p style={{ fontSize: '12px', margin: '0px' }}>
                {/* Portugal | Guimarães . França | Paris */}
              </p>
            </div>

            <CircleUp />
          </div>
        </motion.div>
      </div>
      {/* <motion.h2 style={{ y }}>{`#00${id}`}</motion.h2> */}
    </motion.section>
  );
}

const Home = ({ identificador, userInfo, setUserInfo }) => {
  let img1 = '';
  let img2 = '';

  if (userInfo && userInfo.fundo1) {
    img1 = `url('${userInfo && userInfo.fundo1}')`;
  } else {
    img1 = `url('./bg1.jpg')`;
  }
  if (userInfo && userInfo.fundo2) {
    img2 = `url('${userInfo && userInfo.fundo2}')`;
  } else {
    img2 = `url('./bg2.jpg')`;
  }

  return (
    <>
      {[1, 2].map((image) => (
        <motion.div
          initial={{ opacity: 0, transition: { duration: 1 } }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          key={image}
          style={{
            backgroundImage: image === 1 ? img1 : img2,
            backgroundSize: 'cover',
          }}
        >
          {image === 1 ? (
            <FirstPage
              id={image}
              identificador={identificador}
              userInfo={userInfo}
            />
          ) : (
            <SecondPage
              id={image}
              identificador={identificador}
              userInfo={userInfo}
            />
          )}
        </motion.div>
      ))}
    </>
  );
};

export default function BackCard() {
  let identificador = useLocation().pathname.split('/')[1];
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    axios
      .get(`${BASE_URL}/cartao/${identificador}`)
      .then(({ data }) => {
        // console.log(data);
        setUserInfo(data.yData[0]);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Routes>
        <Route
          path='/:identificador'
          element={
            <Home
              identificador={identificador}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
            />
          }
        />
      </Routes>
    </>
  );
}
