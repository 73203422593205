import FeatherIcon from 'feather-icons-react';

const CircleDown = ({ userInfo }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        // border:
        //   userInfo && userInfo.corLetras !== '#FFFFFF'
        //     ? `${userInfo.corLetras} 1px solid`
        //     : '#FFFFFF',
        border:
          userInfo && userInfo.corLetras !== '#FFFFFF'
            ? `${userInfo.corLetras} 2px solid`
            : '#004989 2px solid',
        width: '35px',
        height: '35px',
        // '&:hover': {
        //   background: '#004989',
        // },
      }}
      onClick={() =>
        window.scrollTo({
          top: 1500,

          behavior: 'smooth',
        })
      }
    >
      <FeatherIcon
        icon='arrow-down'
        size={25}
        style={{
          color:
            userInfo && userInfo.corLetras !== '#FFFFFF'
              ? `${userInfo.corLetras} `
              : '#004989 ',
          strokeWidth: '2px',
        }}
      />
    </div>
  );
};

export default CircleDown;
